<template>
  <div ref="el" class="flex flex-col w-full bg-white p-md pb-l">
    <p class="font-bold text-left text-alt-2xl font-deco-alt">
      {{ t('returns.exclusionReason.title') }}
    </p>
    <ul class="list pt-sm">
      <li
        v-for="(item, index) in upperListItems"
        :key="index"
        class="flex items-center justify-start py-xs"
      >
        <div
          class="rounded-[32px] border-2 border-dotted w-[32px] h-[32px] mr-xs border-primary-base"
        >
          <div
            class="w-[24px] h-[24px] rounded-xl m-[2px] text-white flex place-items-center justify-center bg-primary-base"
          ></div>
        </div>
        {{ t(item.title) }}
      </li>
      <ul class="pl-lg mt-sm">
        <li
          v-for="(item, index) in lowerListItems"
          :key="index"
          class="flex justify-start pb-xs"
        >
          <div class="flex justify-start mt-[2px] mr-sm shrink-0">
            <img :src="item.imgSrc" class="h-[20px] w-[20px] shrink-0" />
          </div>
          <div>
            <strong class="block">{{ t(item.headline) }}</strong>
            {{ t(item.text) }}
          </div>
        </li>
      </ul>
    </ul>
  </div>
</template>
<script setup lang="ts">
import { useImages } from '@/composables/useImages';

const { t } = useTrans();

defineProps({
  headline: {
    type: String,
    default: '',
  },
  subHeadline: {
    type: String,
    default: '',
  },
  cta: {
    type: String,
    default: '',
  },

  image: {
    type: String,
    default: '',
  },
  isLoading: {
    type: Boolean,
    default: false,
  },
  hasClose: {
    type: Boolean,
    default: false,
  },
});

defineEmits<{
  (e: 'clickCta'): void;
  (e: 'close'): void;
}>();

const upperListItems = [
  { title: 'returns.exclusionReason.listItem1' },
  { title: 'returns.exclusionReason.listItem2' },
  { title: 'returns.exclusionReason.basic.title' },
];

const lowerListItems = [
  {
    headline: 'returns.exclusionReason.basic.listItem1.title',
    text: 'returns.exclusionReason.basic.listItem1.text',
    imgSrc: getImageSrc('@/assets/images/info-icon-medicine.svg'),
  },
  {
    headline: 'returns.exclusionReason.basic.listItem2.title',
    text: 'returns.exclusionReason.basic.listItem2.text',
    imgSrc: getImageSrc('@/assets/images/info-icon-ambient.svg'),
  },
  {
    headline: 'returns.exclusionReason.basic.listItem3.title',
    text: 'returns.exclusionReason.basic.listItem3.text',
    imgSrc: getImageSrc('@/assets/images/info-icon-no-return.svg'),
  },
  {
    headline: 'returns.exclusionReason.basic.listItem4.title',
    text: 'returns.exclusionReason.basic.listItem4.text',
    imgSrc: getImageSrc('@/assets/images/info-icon-investment-item.svg'),
  },
];

function getImageSrc(srcPath: string): string {
  return useImages().getImageSrc(srcPath);
}
</script>
