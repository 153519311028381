<template>
  <Dialog class="w-[750px]">
    <ReasonList @close="() => dialogStore.closeDialog()" />
  </Dialog>
</template>
<script setup lang="ts">
import Dialog from '@/components/dialog/components/dialogFrame/global/dialogFrame.vue';
import ReasonList from './components/reasonList.vue';
import { useDialogStore } from '~/stores/useDialogStore';
const dialogStore = useDialogStore();
</script>
